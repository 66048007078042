import React, {ChangeEvent, useState} from 'react';

interface ComponentProps {
    icon : string;
    size : string;
    operazione: string;
    vh? : string;
}

const ButtonIcon: React.FC<ComponentProps> = ({ icon, size, operazione, vh = 10}) => {

    return (
        <div className={"row container-fluid min-vh-" + vh + " justify-content-center align-items-center"}>
            <div className="col-md-3">
                <i className={"fa-solid " + icon + " fa-"+ size + "x"}/>
            </div>
            <div className="col-md-1" />
            <div className="col-md-6">
                <h3>
                    {operazione}
                </h3>
            </div>
        </div>
    );
};

export default ButtonIcon;
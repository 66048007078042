import React, {useState} from "react";
import axios from "axios";

export function useStateEmail(){
    let [text, setEmail] = useState('');
    let handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };
    let [errore, setErrore] = useState('');
    return [text,handleEmailChange,errore, setErrore, setEmail ] as const;
}

export function useStateText(){
    let [text, setText] = useState('');
    let handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    };
    let [errore, setErrore] = useState('');
    return [text,handleTextChange, errore, setErrore, setText ] as const;
}

export function useStateSelect(){
    let [selectedValue, setSelectedValue] = useState<string | null>(null);
    let handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedValue(event.target.value);
    };
    let [errore, setErrore] = useState('');
    return [selectedValue,handleChange,errore, setErrore, setSelectedValue ] as const;
}

export function usePhoneNumberSelect(){
    let [phoneNumber, setValue] = useState('');
    let handleChange = (phone : any) => {
        setValue(phone);
    };
    let [errore, setErrore] = useState('');
    return [phoneNumber,handleChange,errore, setErrore, setValue ] as const;
}

export async function setFormError(errore : any, key : string, func : any){
    await func("")
    if(key in errore)
        await func(errore[key])
}

export async function handleErrorCheck(field : string, value : any, setErrore : any, err : string, errore : string, defaultErrore : string){
    let response = await doRequest("/ValidateField/" + field, {"value" : value}, false);
    if(response == undefined) {
        setErrore("Controllo campo mancante")
        return
    }
    if("ERRORE_VALIDAZIONE" in response){
        if(err == "")
            setErrore("");
        if(errore != "")
            setErrore(errore);
        else
            setErrore(defaultErrore);
    }else
        setErrore("");
}

const config = {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
}

export async function checkToken(){
    let token = localStorage.getItem("TOKEN");
    if(token == null)
        return false;

    let response = await doRequest("/NotLoggedUser/CheckToken", {token}, false);
    if(response == undefined)
        return false;

    return "RISULTATO" in response;
}

export async function doRequest(path : any, params : any, token_request : any){
    try {
        if(token_request === true){
            params.token = localStorage.getItem("TOKEN");
        }
        const response = await axios.post('https://tiwbackend.sb.cloudns.ph' + path,
            params, config
        );
        console.log('Response:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error:', error);
    }
}
import React from 'react';
import "../styles/ErrorLabel.css"

interface EmailProps {
    label: string;
}

const Email: React.FC<EmailProps> = ({ label }) => {
    return (
        <label className={"error-label"} style={{display: 'block', marginBottom: '8px', fontWeight: 'bold'}}>
            <span
                dangerouslySetInnerHTML={{ __html: label }}
            />
        </label>
    );
};

export default Email;
import React, {useEffect} from 'react';
import {doRequest, useStateEmail, useStateSelect, useStateText} from "../utils";
import Email from "../generic_components/Email";
import Password from "../generic_components/Password";
import "../styles/Jumbotron.css"
import SubmitButton from "../generic_components/SubmitButton";
import "../styles/GenericLink.css"

interface pageProps {
    title : string
}

const Logout: React.FC<pageProps> = ({title}) => {
    useEffect(() => {
        document.title = title
        localStorage.removeItem("TOKEN")
        window.location.href = "/Home"
    }, []);

    return (
        <div />
    );
};

export default Logout;
import React, {useEffect} from 'react';
import {doRequest, useStateEmail, useStateSelect, useStateText} from "../utils";
import Email from "../generic_components/Email";
import Password from "../generic_components/Password";
import "../styles/Jumbotron.css"
import SubmitButton from "../generic_components/SubmitButton";
import "../styles/GenericLink.css"

interface pageProps {
    title : string
}

const Login: React.FC<pageProps> = ({title}) => {
    useEffect(() => {
        document.title = title
    }, []);

    const [email,handleEmailChange ] = useStateEmail();
    const [password, handleTextChange] = useStateText();

    const handleSubmit = async () => {
        let response = await doRequest("/NotLoggedUser/Login", {email, password}, false);
        if(response !== undefined){
            if("RISULTATO" in response && "TOKEN" in response.RISULTATO) {
                localStorage.setItem("TOKEN", response["RISULTATO"]["TOKEN"]);
                window.location.href = "/Home"
            }
            else if("ERRORE" in response)
                alert(response.ERRORE)
        }
    };

    return (
        <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="container-fluid jumbotron-login jumbotron col-lg-8 border" style={{borderStyle : "solid", borderRadius: "70px"}}>
                <div className="row container-fluid py-4">
                    <div className="col-lg-12">
                        <h1 className="text-center">
                            LOGIN
                        </h1>
                        <br/>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className={"col-lg-8"} />
                    <div className="col-lg-8">
                        <div className="col-lg-12">
                            <form role="form">
                                <div className="form-group">
                                    {/* <Select id={"select"} label={"Seleziona"} options={options} onChange={handleSelectionChange} /> */}
                                    <div className={"row"}>
                                        <Email value={email} onChange={handleEmailChange} label="E-mail" placeholder="tuamail@dominio.com" check={false}/>
                                    </div>
                                    <div className={"row"}>
                                        <Password id={"password"} value={password} onChange={handleTextChange} label="Password" placeholder="" check={false}/>
                                    </div>
                                    <div className={"row"}>
                                        <SubmitButton label={"LOGIN"} onSubmit={handleSubmit}/>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className={"row container-fluid py-2"}>
                            <div className={"col-lg-6"}>
                                <a href={"/ResetPassword"} className={"generic-link"}> Password dimenticata?</a>
                            </div>
                            <div className={"col-lg-6 text-end"}>
                                <a href={"/SignUp"} className={"generic-link"}>Oppure registrati </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
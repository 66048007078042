import React, {useEffect} from 'react';
import {doRequest, useStateEmail, useStateSelect, useStateText} from "../utils";
import Email from "../generic_components/Email";
import Password from "../generic_components/Password";
import "../styles/Jumbotron.css"
import SubmitButton from "../generic_components/SubmitButton";
import "../styles/GenericLink.css"
import Card from "../generic_components/Card";
import ButtonIcon from "../generic_components/ButtonIcon";
import Carousel from "../generic_components/Carousel";

interface pageProps {
    title : string
}

const Home: React.FC<pageProps> = ({title}) => {
    useEffect(() => {
        document.title = title
    }, []);

    return (
        <div className="container">
            <div className="row min-vh-10">
                <div className="col-md-12">
                    <h1 className="text-center margin-contorno-25">
                        BENVENUTO NELLA TUA HOMEPAGE
                    </h1>
                </div>
            </div>
            <div className="row container-fluid py-4 jumbotron-login border min-vh-30" style={{borderStyle : "solid", borderRadius: "50px"}}>
                <div className="row container-fluid">
                    <h2 className={"h15 margin-contorno-10"}>
                        Itinerari suggeriti
                    </h2>
                    <div className="col-md-8">

                        <div className="row container-fluid">
                            <Card img={"/valtellina.webp"} title={"Valtellina"} description={"Itinerario veloce per un paio di giorni in valtellina"} />
                            <Card img={"/valtellina.webp"} title={"Valtellina"} description={"Itinerario veloce per un paio di giorni in valtellina"} />
                            <Card img={"/valtellina.webp"} title={"Valtellina"} description={"Itinerario veloce per un paio di giorni in valtellina"} />
                        </div>
                    </div>
                    <div className="col-md-4 align-content-center">
                        <ButtonIcon icon={"fa-magnifying-glass"} size={"5"} operazione={"Cerca un itinerario"}/>
                        <ButtonIcon icon={"fa-plus"} size={"7"} operazione={"Crea un itinerario"}/>
                        <ButtonIcon icon={"fa-sliders"} size={"5"} operazione={"Gestisci itinerari"}/>
                    </div>
                </div>
            </div>
            <div className={"row container-fluid py-3"}></div>
            <div className="row container-fluid jumbotron-login border min-vh-30"
                 style={{borderStyle: "solid", borderRadius: "50px"}}>
                <div className="col-md-12">
                    <h2 className={"h15 margin-contorno-10"}>
                        I tuoi itinerari di successo
                    </h2>
                    <div className="row container-fluid">
                        <div className="col-md-8">
                            <Carousel />
                        </div>
                        <div className="col-md-4">
                            <h3>
                                h3. Lorem ipsum dolor sit amet.
                            </h3>
                        </div>
                        <div className="col-md-4">
                            <h3>
                                h3. Lorem ipsum dolor sit amet.
                            </h3><img alt="Bootstrap Image Preview" src="/img/sports-q-c-140-140-3.jpg" className="rounded-circle" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
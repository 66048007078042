import React, {ChangeEvent, useState} from 'react';
import "../styles/Card.css"

interface ComponentProps {
    img : string;
    title : string;
    description : string;
    operazione?: string;
    md? : string;
}

const Card: React.FC<ComponentProps> = ({ img, title, description, operazione = "Apri", md = 4 }) => {

    return (
        <div className={"col-md-" + md}>
            <div className="card">
                <img className="card-img-top" alt="Bootstrap Thumbnail First" src={img}/>
                <div className="card-block bg-dark">
                    <div className={"card-body-margin"}>
                        <h5 className="font-card-title">
                            {title}
                        </h5>
                        <p className="font-card-text">
                            {description}
                        </p>
                        <p>
                            <a className="btn btn-secondary" href="#">{operazione}</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card;
import React, {ChangeEvent, useState} from 'react';
import ErrorLabel from "./ErrorLabel";
import {doRequest, handleErrorCheck} from "../utils";


interface PasswordProps {
    id: string;
    value: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    label: string;
    placeholder?: string;
    obbligatorio?: boolean;
    errore?: string;
    check?: boolean;
}

const Password: React.FC<PasswordProps> = ({ id, value, onChange, label, placeholder = '...', obbligatorio = false, errore = "", check = true }) => {
    const defaultErrore = "La password non è abbastanza sicura:<br/>\n" +
                            "1. Lunghezza minima: Almeno 6 caratteri.<br/>\n" +
                            "2. Lettere maiuscole e minuscole: Deve contenere sia lettere maiuscole che minuscole.<br/>\n" +
                            "3. Numeri: Deve includere almeno un numero.<br/>\n" +
                            "4. Caratteri speciali: Deve contenere almeno un carattere speciale (es. !@#$%^&*).<br/>\n" +
                            "5. Assenza di spazi: Non deve contenere spazi."
    const [err, setErrore] = useState("");

    const checkErrori = async () => {
        await handleErrorCheck("Password", value, setErrore, err, errore, defaultErrore);
    }

    return (
        <div style={{ marginBottom: '16px' }}>
            <label htmlFor={id} style={{ display: 'block', marginBottom: '8px', fontWeight: 'bold' }}>
                {label}{obbligatorio ? " (*)" : ""}
            </label>
            <input
                type="password"
                id={id}
                value={value}
                onChange={onChange}
                onBlur={check ? checkErrori : undefined}
                placeholder={placeholder}
                style={{
                    padding: '8px',
                    fontSize: '16px',
                    width: '100%',
                    borderRadius: '4px',
                    border: '1px solid #ccc',
                }}
            />
            <ErrorLabel label={err} />
        </div>
    );
};

export default Password;
import React, {useEffect} from 'react';
import '../styles/Navbar.css';
import {doRequest} from "../utils";

const Navbar: React.FC = () => {
    const logo = '/site_logo.webp';
    const logged = localStorage.getItem("TOKEN") !== null;
    const menuNotLogged = [
        { label: 'Home', href: '/', icon : null },
    ];

    const menuLogged = [
        { label: 'Home', href: '/', icon : null },
        { label: 'Account', href: '/', icon : null },
        { label: 'Logout', href: '/Logout', icon : "fas fa-sign-out-alt inverted-icon" },
    ];

    const menu = logged ? menuLogged : menuNotLogged

    useEffect(() => {
        // Simula un'operazione prima del rendering del contenuto principale
        const fetchData = async () => {
            let response = await doRequest("/navbar/getItems", {}, true);
            //console.log(response);
        };
        fetchData();
    }, []);

    return (
        <nav className={"navbar-site"}>

            {/* Logo */}
            <div className={"logo-container"}>
                <img src={logo} alt="Logo" className={"logo"} />
            </div>

            {/* Menu */}
            <ul className={"menu"}>
                {menu.map((item, index) => (
                    <li key={index} className={"menu-item"}>
                        <a href={item.href} className={"menu-link"}>
                            {item.label}&nbsp;{item.icon !== null ? <i className={item.icon} /> : ""}
                        </a>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default Navbar;

import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from "./generic_pages/Login";
import Navbar from "./generic_components/Navbar";
import SignUp from "./generic_pages/SignUp";
import Home from "./generic_pages/Home";
import Logout from "./generic_pages/Logout";
import {checkToken} from "./utils";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const SiteName = "ITINERARI - "

const logged = await checkToken();
const LoginPage = <Login title={SiteName + "Login"} />

root.render(
  <React.StrictMode>
      <BrowserRouter>
          <Navbar />
          <Routes>
              <Route path="/Login" element={LoginPage} />
              <Route path="/SignUp" element={<SignUp title={SiteName + "Registrazione"} />} />
              <Route path="/Logout" element={<Logout title={SiteName + "Logout"} />} />

              <Route path="/" element={logged ?  <Home title={SiteName + "Home"}/> : LoginPage } />
              <Route path="/Home" element={logged ?  <Home title={SiteName + "Home"}/> : LoginPage } />
          </Routes>
      </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
